import React, { Component } from 'react';
import  $ from "jquery";
import Carousel from 'react-gallery-carousel';
import './BaseUrl';
import 'react-gallery-carousel/dist/index.css';
import {Link} from 'react-router-dom';
import Slider from "react-slick";



class Conference_Centre extends Component{
  constructor(){
    super();
    this.state={
      base_url:global.BaseUrl,
      assets_url:global.AssetsUrl,
      banner_img:[],
      relatedoc:[],
    }
  }

    componentDidMount() {
        <script> 
        $(document).ready(function(){
          $("#flip1").click(function(){
            $("#panel1").slideToggle("show");
          })
        });

        $(document).ready(function(){
          $("#flip2").click(function(){
            $("#panel2").slideToggle("show");
          })
        });

         $(document).ready(function(){
          $("#flip3").click(function(){
            $("#panel3").slideToggle("show");
          })
        });
        </script>

fetch(`${this.state.base_url}/getfilesbyid/8`).then((resp)=>{
  resp.json().then((result)=>{
    this.setState({ relatedoc: result});        
  })
});
fetch(`${this.state.base_url}/getBannerById/8`).then((resp)=>{
  resp.json().then((result)=>{
     console.log(result);
    this.setState({ banner_img: result.banner_images});
    // this.setState({ headingType: result.data});           
  })
});
    
    }

    render(){
      const {relatedoc,banner_img} = this.state;
            
        return(

            <>
          
      <div className="container-fluid slider_top">
          <div className="row">
            {banner_img.map((banner, i) => {
              return (
                <div className="col-md-4 col-12">
                  <img src={`${this.state.assets_url}/uploads/${banner}`} alt="" className='bnr_img_30 padding_set' />
                </div>
              )
            })}
          </div>
        </div>

      <section className="counter-section-03 padding-top-50 padding-bottom-50 mt-4" style={{padding:'0px 0px 0px 0px'}}>
    <div className="container" > 
        <div className="row " >
           
           <div className="col-md-12 mt-4" style={{color:'#000',fontSize:'16px'}}>
           <div className="col-md-12 col-12">
                <p className="table_content">Designed to exclusively cater to the requirements of meetings, townhalls, conferences, and events with best-in-class facilities and an extensive catering menu.</p>
                <p className="table_content">Our Catering Department brings together culinary excellence and exemplary service to create a seamless event. It is our goal to provide fresh ingredients using local products, and carefully crafted menus each day. We go beyond serving delicious food; we offer options for every taste and budget and enhance moments that matter for each event or business meeting. It is our commitment to create an exceptional experience that builds engagement to help you meet better, work better, and celebrate better.</p>
                <p className="table_content">Allow us to help you plan an event worth remembering! </p>

                <ul className="red_border col-md-9">
                <li className="text_justify">Ready to reserve catering? Book now through <a href='https://openspace.gaiacloud.jpmchase.net/dashboard' target='_blank' className='top_heading2'>Go/Openspace</a ></li>
                <li className="text_justify">Let us help you make your culinary experience worth remembering!</li>
                <li><a href="https://foodweb-antelope.ksoftpl.com/avenue-uptown-6/public/uploads/conference_center_menu.pdf" target='_blank' className='top_heading2'>Conference Center Menu</a > </li>               
                <li><a href="https://foodweb-antelope.ksoftpl.com/avenue-uptown-6/public/uploads/manila_client_center.pdf" target='_blank' className='top_heading2'>Client Center Menu</a > </li>               
                
                {/* <li className="text_justify">Please change Your Safety is Our Top Priority to COVID-19 Event Safety Guidelines</li>                 */}
                </ul>

            </div>
           
           </div>
        </div>
    </div>
</section>

<section className="counter-section-03 padding-top-50 padding-bottom-50 mt-4" style={{padding:'0px 0px 0px 0px'}}>
    <div className="container" > 
        <div className="row " >
           
           <div className="col-md-12 mt-4" style={{color:'#000',fontSize:'16px'}}>
           <div className="col-md-12 col-12">
           <p className="table_heading ">COVID-19 Event Safety Guidelines </p>

                <p className="table_content">We remain committed to the highest standard of food safety, hygiene and sanitation with rigorous hand washing, sanitation of surfaces, daily team health checks and promoting social distancing. We have made some modifications to our menu so that we may continue to keep everyone safe and healthy, including individually packaged meals and recommended safety protocols.</p>
            </div>

            <div className="col-md-12 col-12">
                <p className="table_heading ">We're Here to Help!</p>
                <ul>
                  <li className='table_content' style={{margin:'0px 0px 10px 0px'}}>Allergen information available <Link to='/Allergens' className="top_heading2">here</Link> </li>
                  <li className='table_content'>For any other inquiries, please do not hesitate to contact your Conference Center Team</li>
                </ul>
            </div>

           
           </div>
        </div>
    </div>
</section>
{/* <section className="counter-section-03 padding-top-50 padding-bottom-75 mt-4" >
    <div className="container">
        <div className="row">
           <div className="col-md-12 mt-4">
             
           <div className="col-md-12 col-12">
          
                <p className="table_heading ">Your Safety is Our Top Priority</p>
                <p className="table_content">We remain committed to the highest standard of food safety, hygiene and sanitation with rigorous hand washing, sanitizing surfaces, daily team health checks and promoting social distancing. We have made some modifications to our menu so that we may continue to keep everyone safe and healthy, including individually packaged meals and recommended safety protocols.</p>
                
            </div>

            <div className="col-md-12 col-12">
                <p className="table_heading ">We're Here to Help!</p>
                <ul>
                  <li className='table_content' style={{margin:'0px 0px 10px 0px'}}>Allergen information available</li>
                  <li className='table_content'>For any other inquiries, do not hesitate to contact your Amenity Services Team</li>
                </ul>
            </div>
           

           
           </div>
        </div>

       
    </div>
</section> */}
   


{
           relatedoc.length == 0 ? null :  
            <div className="row bg-color padding-bottom-100 related_doc">
           <div className="container">
           <h3 className="res-title" style={{color:'#000',marginTop:'40px'}}>Related Document</h3>
           <div className="row mb-3">
           <div className="col-12">
           { relatedoc.map(doc=>
             
             <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12 ">
            <a href={`${this.state.assets_url}/uploads/${doc.file_name}`} target="_blank"><p class="des realtedoc">{doc.file_title}</p></a>
             </div>
               ) }
           </div>
           </div> 
          
           </div>
           </div> 
         }
         
            </>
        );
    }
}

export default Conference_Centre;
